import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import Card from '../components/card'
import ColumnAlternating from '../components/columnAlternatingStyled'

const SolutionsTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}           
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}             
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}}
                />
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>         

        {post.frontmatter.section && (
          <>
            {post.frontmatter.section.map((sections, index) => (
              <Fragment key={index}>
                {sections.placement === 'cards' && (
                  <>
                    {sections.feature.filter( (solution) => solution.featured === true ).length > 0 && (
                      <section id="featured" className="bg-light-blue">
                        <MDBContainer>
                              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5"> Featured {post.frontmatter.name.toLowerCase()} </h2>
                          <MDBRow>
                            {sections.feature.filter((solution) => solution.featured === true).length === 1 ? (
                              <>
                                {sections.feature.filter((solution) => solution.featured === true).map((features) => (
                                  <ColumnAlternating
                                    key={1}
                                    textCol={'7'}
                                    imageCol={'5'}
                                    title={features.title}
                                    subtitle={features.subtitle}
                                    subtitlecolour="text-very-dark-blue"
                                    description={features.description}
                                    image={ features.image.childImageSharp.gatsbyImageData }
                                    alt={features.alttext}
                                    placement="left"
                                    titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                                    link={features.link}
                                    colour="ficon-sl"
                                  />
                                  ))}
                              </>
                            ) : (
                              <>
                                {sections.feature.filter((solution) => solution.featured === true).map((features, index) => (
                                  <Card
                                    key={index}
                                    collg="4"
                                    colmd="6"
                                    height="10.5rem"
                                    title={features.title}
                                    subtitle={features.subtitle}
                                    description={features.description}
                                    image={ features.image ? features.image.childImageSharp.gatsbyImageData : null }
                                    alt={features.alttext}
                                    link={features.link}
                                    titleclass="text-medium"
                                    descriptionClass="text-card-small"
                                  />
                                  ))}
                              </>
                            )}
                          </MDBRow>
                        </MDBContainer>
                      </section>
                    )}

                  {sections.feature.filter((solution) => solution.featured === false).length > 0 && 
                    <section className={ sections.feature.filter((solution) => solution.featured === true).length > 0 ? 'bg-white' : 'bg-light-blue' } >
                      <MDBContainer>
                        <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5"> {sections.title} </h2>
                        <MDBRow>
                          {sections.feature.filter((solution) => solution.featured === false).map((features, index) => (
                            <Card
                              key={index}
                              collg="4"
                              colmd="6"
                              height="10.5rem"
                              title={features.title}
                              subtitle={features.subtitle}
                              description={features.description}
                              image={ features.image ? features.image.childImageSharp.gatsbyImageData : null }
                              alt={features.alttext}
                              link={features.link}
                              titleclass="text-medium"
                              descriptionClass="text-card-small"
                            />
                            ))}
                        </MDBRow>
                      </MDBContainer>
                    </section>
                     }
                  </>
                )}

                {sections.placement === 'list' && (
                  <section className={index % 2 ? 'bg-white' : 'bg-light-blue'}>
                    <MDBContainer>
                      <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium pb-5"> {sections.title} </h2>
                      {sections.feature.map((features, index) => (
                        <MDBRow className="px-3" key={index}>
                          <MDBCol lg="12" md="12">
                            <MDBRow style={{ minHeight: features.subtitle ? '8rem' : '4rem', marginBottom: '5rem', borderLeft: '6px solid #C4122F', }} className="bg-white rounded innovation-box p-4" >
                              <MDBCol lg="9" md="7" className="my-auto">
                                <h3 className="font-w-600 text-extra-large mb-0"> {features.title} </h3>
                                {features.subtitle && (
                                  <p className="font-w-400 text-medium mb-0"> {features.subtitle} </p>
                                )}
                                {features.date && (
                                <p className="font-alt font-w-400 text-medium-small mt-2 mb-0"> {features.date} </p>
                                )}
                              </MDBCol>
                              <MDBCol lg="3" md="5" className="my-auto">
                              {features.link && (
                                  <>
                                    <a href={features.link} className="nav-link btn btn-mdb-color btn-sm-block float-md-right mt-3 mt-md-0" target="_blank" rel="noopener" > <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mr-2" />View article</a>
                                  </>
                                )}
                                {features.document && (
                                  <>
                                    <a href={features.document.publicURL} className="nav-link btn btn-mdb-color btn-sm-block float-md-right mt-3 mt-md-0" target="_blank" rel="noopener" > <FontAwesomeIcon icon={faDownload} className="mr-2" />Download</a>
                                  </>
                                )}
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      ))}
                    </MDBContainer>
                  </section>
                )}
              </Fragment>
            ))}
          </>
        )}
      
      </Layout>
    </div>
  )
}
export default SolutionsTemplate

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(
     frontmatter: { name: { eq: "solutions" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }        
        name
        title
        subtitle
        openingpara
        alttext
        ctatext
        ctaslug         
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          subtitle         
          placement
          feature {
            title
            subtitle
            date(formatString: "MMMM DD, YYYY")
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            alttext
            linktext
            link
            featured
          }
        }
      }
      html
    }
  }
`